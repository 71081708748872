import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss']
})
export class BillboardComponent implements OnInit {
  @Input() id;
  @Input() style;
  @Input() color;
  @Input() image;
  @Input() background;
  @Input() xPos;
  @Input() yPos;

  constructor() { }

  ngOnInit() {
  }

}
