import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-workgrid',
  templateUrl: './workgrid.component.html',
  styleUrls: ['./workgrid.component.scss']
})
export class WorkgridComponent implements OnInit {
  @Input() size;

  constructor() { }

  ngOnInit() {
  }

}
