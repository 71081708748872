import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-vid',
  templateUrl: './parallax-vid.component.html',
  styleUrls: ['./parallax-vid.component.scss']
})
export class ParallaxVidComponent implements OnInit {
  @Input() id;
  @Input() color;
  @Input() margin;
  @Input() padding;
  @Input() overlay = 'visible';
  @Input() poster;
  @Input() src;
  @Input() speed = '0.1';

  constructor() { }

  ngOnInit() {
  }

}
