import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.scss']
})
export class ParallaxComponent implements OnInit {
  @Input() id;
  @Input() color;
  @Input() margin;
  @Input() padding;
  @Input() overlay = 'visible';
  @Input() image;
  @Input() speed = '0.1';

  constructor() { }

  ngOnInit() {
  }

}
