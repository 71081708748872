import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-narrow',
  templateUrl: './narrow.component.html',
  styleUrls: ['./narrow.component.scss']
})
export class NarrowComponent implements OnInit {
  @Input() id;
  @Input() style;
  @Input() color;
  @Input() padding;

  constructor() { }

  ngOnInit() {
  }

}
