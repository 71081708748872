import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logocompare',
  templateUrl: './logocompare.component.html',
  styleUrls: ['./logocompare.component.scss']
})
export class LogocompareComponent implements OnInit {
  @Input() id;
  @Input() class;
  @Input() image;

  constructor() { }

  ngOnInit() {
  }

}
