/* tslint:disable */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { DefaultHttpOptions, HttpOptions, APIClientInterface } from './';

import * as models from './models';

export const USE_DOMAIN = new InjectionToken<string>('APIClient_USE_DOMAIN');
export const USE_HTTP_OPTIONS = new InjectionToken<HttpOptions>('APIClient_USE_HTTP_OPTIONS');

type APIHttpOptions = HttpOptions & {
  headers: HttpHeaders;
  params: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
};

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class APIClient implements APIClientInterface {

  readonly options: APIHttpOptions;

  readonly domain: string = `http://localhost:5001`;

  constructor(private readonly http: HttpClient,
              @Optional() @Inject(USE_DOMAIN) domain?: string,
              @Optional() @Inject(USE_HTTP_OPTIONS) options?: DefaultHttpOptions) {

    if (domain != null) {
      this.domain = domain;
    }

    this.options = {
      headers: new HttpHeaders(options && options.headers ? options.headers : {}),
      params: new HttpParams(options && options.params ? options.params : {}),
      ...(options && options.reportProgress ? { reportProgress: options.reportProgress } : {}),
      ...(options && options.withCredentials ? { withCredentials: options.withCredentials } : {})
    };
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameTag(
    args: {
      orgName: string,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
      page?: number,
      limit?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.TagList> {
    const path = `/orgs/${args.orgName}/Tag`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    if ('page' in args) {
      options.params = options.params.set('page', String(args.page));
    }
    if ('limit' in args) {
      options.params = options.params.set('limit', String(args.limit));
    }
    return this.sendRequest<models.TagList>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  postOrgsOrgnameTag(
    args: {
      orgName: string,
      body?: models.TagCreateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Tag> {
    const path = `/orgs/${args.orgName}/Tag`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Tag>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameMedia(
    args: {
      orgName: string,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
      page?: number,
      limit?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.MediaList> {
    const path = `/orgs/${args.orgName}/Media`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    if ('page' in args) {
      options.params = options.params.set('page', String(args.page));
    }
    if ('limit' in args) {
      options.params = options.params.set('limit', String(args.limit));
    }
    return this.sendRequest<models.MediaList>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  postOrgsOrgnameMedia(
    args: {
      orgName: string,
      body?: models.MediaCreateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Media> {
    const path = `/orgs/${args.orgName}/Media`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Media>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnamePost(
    args: {
      orgName: string,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
      page?: number,
      limit?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.PostList> {
    const path = `/orgs/${args.orgName}/Post`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    if ('page' in args) {
      options.params = options.params.set('page', String(args.page));
    }
    if ('limit' in args) {
      options.params = options.params.set('limit', String(args.limit));
    }
    return this.sendRequest<models.PostList>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  postOrgsOrgnamePost(
    args: {
      orgName: string,
      body?: models.PostCreateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Post> {
    const path = `/orgs/${args.orgName}/Post`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Post>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameAuthor(
    args: {
      orgName: string,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
      page?: number,
      limit?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.AuthorList> {
    const path = `/orgs/${args.orgName}/Author`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    if ('page' in args) {
      options.params = options.params.set('page', String(args.page));
    }
    if ('limit' in args) {
      options.params = options.params.set('limit', String(args.limit));
    }
    return this.sendRequest<models.AuthorList>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  postOrgsOrgnameAuthor(
    args: {
      orgName: string,
      body?: models.AuthorCreateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Author> {
    const path = `/orgs/${args.orgName}/Author`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Author>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameMediaId(
    args: {
      orgName: string,
      id: number,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Media> {
    const path = `/orgs/${args.orgName}/Media/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Media>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameMediaId(
    args: {
      orgName: string,
      id: number,
      body?: models.MediaUpdateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Media> {
    const path = `/orgs/${args.orgName}/Media/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Media>('PUT', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 201 ] HTTP response code.
   */
  deleteOrgsOrgnameMediaId(
    args: {
      orgName: string,
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<void> {
    const path = `/orgs/${args.orgName}/Media/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<void>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameTagCount(
    args: {
      orgName: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Model1> {
    const path = `/orgs/${args.orgName}/Tag/count`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Model1>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnamePostId(
    args: {
      orgName: string,
      id: number,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Post> {
    const path = `/orgs/${args.orgName}/Post/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Post>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnamePostId(
    args: {
      orgName: string,
      id: number,
      body?: models.PostUpdateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Post> {
    const path = `/orgs/${args.orgName}/Post/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Post>('PUT', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 201 ] HTTP response code.
   */
  deleteOrgsOrgnamePostId(
    args: {
      orgName: string,
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<void> {
    const path = `/orgs/${args.orgName}/Post/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<void>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameTagId(
    args: {
      orgName: string,
      id: number,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Tag> {
    const path = `/orgs/${args.orgName}/Tag/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Tag>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameTagId(
    args: {
      orgName: string,
      id: number,
      body?: models.TagUpdateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Tag> {
    const path = `/orgs/${args.orgName}/Tag/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Tag>('PUT', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 201 ] HTTP response code.
   */
  deleteOrgsOrgnameTagId(
    args: {
      orgName: string,
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<void> {
    const path = `/orgs/${args.orgName}/Tag/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<void>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameMediaCount(
    args: {
      orgName: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Model2> {
    const path = `/orgs/${args.orgName}/Media/count`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Model2>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameAuthorCount(
    args: {
      orgName: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Model3> {
    const path = `/orgs/${args.orgName}/Author/count`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Model3>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnamePostCount(
    args: {
      orgName: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Model4> {
    const path = `/orgs/${args.orgName}/Post/count`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Model4>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameAuthorId(
    args: {
      orgName: string,
      id: number,
      populate?: boolean,
      inlineBlob?: boolean,
      filter?: string,
      where?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Author> {
    const path = `/orgs/${args.orgName}/Author/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    if ('inlineBlob' in args) {
      options.params = options.params.set('inlineBlob', String(args.inlineBlob));
    }
    if ('filter' in args) {
      options.params = options.params.set('filter', String(args.filter));
    }
    if ('where' in args) {
      options.params = options.params.set('where', String(args.where));
    }
    return this.sendRequest<models.Author>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameAuthorId(
    args: {
      orgName: string,
      id: number,
      body?: models.AuthorUpdateInput,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Author> {
    const path = `/orgs/${args.orgName}/Author/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Author>('PUT', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 201 ] HTTP response code.
   */
  deleteOrgsOrgnameAuthorId(
    args: {
      orgName: string,
      id: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<void> {
    const path = `/orgs/${args.orgName}/Author/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<void>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnamePostIdFieldname(
    args: {
      orgName: string,
      id: number,
      fieldName: string,
      populate?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<string> {
    const path = `/orgs/${args.orgName}/Post/${args.id}/${args.fieldName}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    return this.sendRequest<string>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameTagIdFieldname(
    args: {
      orgName: string,
      id: number,
      fieldName: string,
      populate?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<string> {
    const path = `/orgs/${args.orgName}/Tag/${args.id}/${args.fieldName}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    return this.sendRequest<string>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameAuthorIdFieldname(
    args: {
      orgName: string,
      id: number,
      fieldName: string,
      populate?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<string> {
    const path = `/orgs/${args.orgName}/Author/${args.id}/${args.fieldName}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    return this.sendRequest<string>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getOrgsOrgnameMediaIdFieldname(
    args: {
      orgName: string,
      id: number,
      fieldName: string,
      populate?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<string> {
    const path = `/orgs/${args.orgName}/Media/${args.id}/${args.fieldName}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('populate' in args) {
      options.params = options.params.set('populate', String(args.populate));
    }
    return this.sendRequest<string>('GET', path, options);
  }

  /**
   * Response generated for [ 201 ] HTTP response code.
   */
  postOrgsOrgnameUpload(
    args: {
      orgName: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<void> {
    const path = `/orgs/${args.orgName}/upload`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<void>('POST', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnamePostIdPublished(
    args: {
      orgName: string,
      id: number,
      published: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Post> {
    const path = `/orgs/${args.orgName}/Post/${args.id}/published/${args.published}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Post>('PUT', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameTagIdPublished(
    args: {
      orgName: string,
      id: number,
      published: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Tag> {
    const path = `/orgs/${args.orgName}/Tag/${args.id}/published/${args.published}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Tag>('PUT', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameAuthorIdPublished(
    args: {
      orgName: string,
      id: number,
      published: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Author> {
    const path = `/orgs/${args.orgName}/Author/${args.id}/published/${args.published}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Author>('PUT', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  putOrgsOrgnameMediaIdPublished(
    args: {
      orgName: string,
      id: number,
      published: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.Media> {
    const path = `/orgs/${args.orgName}/Media/${args.id}/published/${args.published}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.Media>('PUT', path, options);
  }

  private sendRequest<T>(method: string, path: string, options: HttpOptions, body?: any): Observable<T> {
    switch (method) {
      case 'DELETE':
        return this.http.delete<T>(`${this.domain}${path}`, options);
      case 'GET':
        return this.http.get<T>(`${this.domain}${path}`, options);
      case 'HEAD':
        return this.http.head<T>(`${this.domain}${path}`, options);
      case 'OPTIONS':
        return this.http.options<T>(`${this.domain}${path}`, options);
      case 'PATCH':
        return this.http.patch<T>(`${this.domain}${path}`, body, options);
      case 'POST':
        return this.http.post<T>(`${this.domain}${path}`, body, options);
      case 'PUT':
        return this.http.put<T>(`${this.domain}${path}`, body, options);
      default:
        console.error(`Unsupported request: ${method}`);
        return throwError(`Unsupported request: ${method}`);
    }
  }
}
