import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-about-nav',
  templateUrl: './about-nav.component.html',
  styleUrls: ['./about-nav.component.scss']
})
export class AboutNavComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) { }

  ngOnInit() {
  }

}
