import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutComponent } from './pages/about/about.component';
import { StudioComponent } from './pages/studio/studio.component';
import { SmallgroupsComponent } from './pages/smallgroups/smallgroups.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { MentorsComponent } from './pages/mentors/mentors.component';
import { JourneyComponent } from './pages/journey/journey.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { CharityComponent } from './pages/charity/charity.component';
import { PressComponent } from './pages/press/press.component';
import { TreadmillsComponent } from './pages/treadmills/treadmills.component';
import { TermsComponent } from './pages/terms/terms.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NewsComponent } from './pages/news/news.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CovidComponent } from './pages/covid/covid.component';

declare var $: any;

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full'
  },
  {
    path: 'about',
    component: AboutComponent
  },
  // {
  //   path: 'charity',
  //   component: CharityComponent
  // },
  {
    path: 'youth-academy',
    component: CharityComponent
  },
  {
    path: 'press',
    component: PressComponent
  },
  {
    path: 'classes',
    component: ClassesComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'experience',
    component: ExperienceComponent
  },
  {
    path: 'journey',
    component: JourneyComponent
  },
  {
    path: 'mentors',
    component: MentorsComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/:articleId',
    component: NewsComponent
  },
  {
    path: 'packages',
    component: PricingComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'privatetraining',
    component: SmallgroupsComponent
  },
  {
    path: 'studio',
    component: StudioComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'treadmills',
    component: TreadmillsComponent
  },
  {
    path: 'schedule',
    component: ScheduleComponent
  },
  {
    path: 'covid',
    component: CovidComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    router.events.subscribe(() => {
      console.log('closing all modals');
      $('.modal').modal('hide');
      $('#navbar-collapse-1').collapse('hide');
    });
  }
}
