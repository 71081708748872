import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pricepanel-secondary',
  templateUrl: './pricepanel-secondary.component.html',
  styleUrls: ['./pricepanel-secondary.component.scss']
})
export class PricepanelSecondaryComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {

  }

  ngOnInit() {
  }

}
