import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-cdc-guideline',
  templateUrl: './cdc-guideline.component.html',
  styleUrls: ['./cdc-guideline.component.scss']
})
export class CdcGuidelineComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {

  }

  ngOnInit() {
  }

}
