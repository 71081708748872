import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-aspect16by9',
  templateUrl: './aspect16by9.component.html',
  styleUrls: ['./aspect16by9.component.scss']
})
export class Aspect16by9Component implements OnInit {
  @Input() id;
  @Input() autoplay;
  @Input() loop;
  @Input() muted;
  @Input() playsinline;
  @Input() controls;
  @Input() poster;
  @Input() src;

  constructor() { }

  ngOnInit() {
  }

}
