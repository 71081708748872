import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {
  @Input() style;
  @Input() link;
  @Input() company;
  @Input() title;
  @Input() desc;
  @Input() btn;
  @Input() image;

  constructor() { }

  ngOnInit() {
  }

}
