import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit, AfterViewInit {
  viewInitialized = false;

  constructor(public baseComponent: BaseComponent) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
    }, 1000);
  }
}
