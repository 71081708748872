import { Component, Inject, OnInit } from '@angular/core';
import { Post, PostList } from '../../../generated/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { APIClient } from '../../../generated';
import * as moment from 'moment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  post: Post;
  posts: Post[] = [];
  type = 'All';
  pageNumber = 1;
  pageSize = 6;
  articleId: number;
  section;
  loading = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              private apiClient: APIClient
  ) {
    this.articleId = parseInt(this.route.snapshot.paramMap.get('articleId'), 10);
    console.log('NewsPage(): paramMap', this.route.snapshot.paramMap);
    this.section = this.route.snapshot.paramMap.get('section');
    console.log('NewsPage(): articleId', this.articleId, 'section', this.section);
  }

  ngOnInit() {
    this.init(this.articleId, this.section);
  }

  async init(articleId, section) {
    console.log('NewsPage(): loading articles', articleId, this.posts);

    const postsSub = await this.apiClient.getOrgsOrgnamePost({
      orgName: 'global',
      populate: true,
      page: this.pageNumber,
      limit: this.pageSize,
      // filter: '-heroImage'
    });

    postsSub.subscribe((res) => {
      console.log('NewsPage(): loaded articles', res);
      this.loading = false;
      const posts = res as Post[];
      posts.forEach(p => {
        p.postDate = moment(p.postDate).format('MM.DD.YY');
      });
      this.posts = posts;

      if (!!articleId) {
        this.post = this.posts.find(p => {
          return p.id === articleId;
        });
        console.log('NewsPage(): found article', this.post);
      }
    });

    if (!!section) {
      this.type = section;
    }
  }

  getPosts() {
    if (!!this.posts) {
      let articles = this.posts.concat([]);
      articles = articles.reverse();
      const filtered = articles.filter(a => a.type === this.type || this.type === 'All');
      const start = (this.pageNumber - 1) * this.pageSize;
      return filtered.slice(start, start + this.pageSize);
      // return this.posts;
    }
    return [];
  }

  getPostImageUrl(post: Post): string {
    return `/api${this.post.heroImage}`;
  }

  // async getPostImage(postId: number): Promise<string> {
  //   // console.log('getPostImage() 1', postId);
  //   // const heroImage = await this.apiClient.getOrgsOrgnamePostIdFieldname({
  //   //   orgName: 'global',
  //   //   id: postId,
  //   //   fieldName: 'heroImage'
  //   // }).toPromise();
  //   // return heroImage;
  //   return null;
  // }

  getColClasses(length: number) {
    if (length >= 3) {
      return 'col-xs-12 col-sm-6 col-md-4 col-lg-4';
    } else if (length === 2) {
      return 'col-xs-12 col-sm-6 col-md-6 col-lg-6';
    } else if (length === 1) {
      return 'col-xs-12 col-sm-12 col-md-12 col-lg-12';
    }
  }

  public triggerScrollTo() {
    document.getElementById('top').scrollIntoView();
  }

  prevPage(e) {
    e.preventDefault();
    this.triggerScrollTo();
    this.pageNumber--;
  }

  nextPage(e) {
    e.preventDefault();
    this.triggerScrollTo();
    this.pageNumber++;
  }

  nextArticle(e) {
    e.preventDefault();
    this.triggerScrollTo();
    // const currentIndex = this.posts.findIndex(a => a.id === this.post.id);
    // if (this.posts.length > currentIndex + 1) {
    //   const article = this.posts[currentIndex + 1];
    //   // window.location.href = `/news/${article.id}`;
    //   this.nav.to('news', {articleId: article.id})
    // }
  }

  haveNextArticle() {
    // const currentIndex = this.posts.findIndex(a => a.id === this.post.id);
    // if (this.posts.length > currentIndex + 1) {
    //   return true;
    // }
  }

  prevArticle(e) {
    e.preventDefault();
    this.triggerScrollTo();
    // const currentIndex = this.posts.findIndex(a => a.id === this.post.id);
    // if (currentIndex - 1 >= 0) {
    //   const article = this.posts[currentIndex - 1];
    //   // window.location.href = `/news/${article.id}`;
    //   this.nav.to('news', {articleId: article.id})
    // }
  }

  havePrevArticle() {
    // const currentIndex = this.posts.findIndex(a => a.id === this.post.id);
    // if (currentIndex - 1 >= 0) {
    //   return true;
    // }
  }

}
