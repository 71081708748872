import { AfterViewInit, Component, Inject, NgZone, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit, AfterViewInit {
  @ViewChild('video', { read: ViewContainerRef, static: false }) videoEl: ViewContainerRef;

  constructor(private route: ActivatedRoute,
              public baseComponent: BaseComponent,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  async init() {
    // console.log('starting video...');
    this.videoEl.element.nativeElement.play().then(() => {
      console.log('playing video');
    }).catch((e: Error) => {
      // console.error('failed to start video', e, e.message);
      // ignore this error and try to play again in 5 seconds
      setTimeout(() => {
        this.init();
      }, 3000);
    });
  }
}
