import { Meta } from '@angular/platform-browser';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BaseComponent {
  bodyClass = '';
  // pageCategory = 'Home';
  // categoryURL = '/';
  pageTitle = 'jumaFit';
  pageURL = 'http://www.jumafit.com/';
  pageImage = 'http://www.jumafit.com/images/fb-og-image.jpg';
  pageDescription = 'welcome to juma. the treadmill studio for everyone.';
  renderer: Renderer2;

  constructor(private meta: Meta,
              private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document: Document) {

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updatePageInfo(data: {
    bodyClass?: string,
    pageCategory?: string,
    categoryURL?: string,
    pageTitle?: string,
    pageURL?: string,
    pageImage?: string,
    pageDescription?: string
  }) {
    // console.log('updatePageInfo(): ', data);

    const keys = Object.keys(data);
    if (keys.indexOf('bodyClass') !== -1) {
      // console.log('bodyClass', this.bodyClass, data.bodyClass);
      if (!!this.bodyClass) {
        this.renderer.removeClass(this.document.body, this.bodyClass);
      }
      if (!!data.bodyClass && data.bodyClass !== '') {
        this.bodyClass = data.bodyClass;
        this.renderer.addClass(this.document.body, data.bodyClass);
      }
    }
    // if (keys.indexOf('pageCategory')) {
    //   this.pageCategory = data.pageCategory;
    // }
    // if (keys.indexOf('categoryURL')) {
    //   this.categoryURL = data.categoryURL;
    // }

    // this.meta.removeTag('og:title');
    // this.meta.removeTag('twitter:title');
    // this.meta.removeTag('og:image');
    // this.meta.removeTag('twitter:image');
    // this.meta.removeTag('og:description');
    // this.meta.removeTag('description');

    if (keys.indexOf('pageTitle') !== -1) {
      this.pageTitle = data.pageTitle;
      this.document.title = data.pageTitle;
      this.meta.updateTag({ name: 'og:title', content: data.pageTitle });
      this.meta.updateTag({ name: 'twitter:title', content: data.pageTitle });
    }
    if (keys.indexOf('pageURL') !== -1) {
      this.pageURL = data.pageURL;
      this.meta.updateTag({ name: 'og:url', content: data.pageURL });
    }
    if (keys.indexOf('pageImage') !== -1) {
      this.pageImage = data.pageImage;
      this.meta.updateTag({ name: 'og:image', content: data.pageImage });
      this.meta.updateTag({ name: 'twitter:image', content: data.pageImage });
    }
    if (keys.indexOf('pageDescription') !== -1) {
      this.pageDescription = data.pageDescription;
      this.meta.updateTag({ name: 'og:description', content: data.pageDescription });
      this.meta.updateTag({ name: 'description', content: data.pageDescription });
    }
  }
}
