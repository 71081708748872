import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-tile-video',
  templateUrl: './featured-tile-video.component.html',
  styleUrls: ['./featured-tile-video.component.scss']
})
export class FeaturedTileVideoComponent implements OnInit {
  @Input() style;
  @Input() link;
  @Input() company;
  @Input() title;
  @Input() desc;
  @Input() btn;
  @Input() videoID;
  @Input() videoSrc;
  @Input() videoPoster;

  constructor() { }

  ngOnInit() {
  }

}
