import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-vid',
  templateUrl: './full-vid.component.html',
  styleUrls: ['./full-vid.component.scss']
})
export class FullVidComponent implements OnInit {
  @Input() id;
  @Input() color;
  @Input() margin;
  @Input() padding;
  @Input() videoID;
  @Input() videoURL;
  @Input() videoPoster;

  constructor() { }

  ngOnInit() {
  }

}
