import { AfterContentChecked, AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-healcode-widget',
  templateUrl: './healcode-widget.component.html',
  styleUrls: ['./healcode.component.scss']
})
export class HealcodeWidgetComponent implements OnInit, AfterViewInit, AfterContentChecked {
  delay = 0;
  show = true;
  content = '';
  @Input() version: string;
  @Input() linkClass: string;
  @Input() type: string;
  @Input() innerHtml: string;
  @Input() widgetPartner: string;
  @Input() widgetId: string;
  @Input() widgetVersion: string;
  @Input() siteId = '59430';
  @Input() mbSiteId = '988900';
  @Input() serviceId: string;
  @Input() contractId: string;
  @Input() hasTermsAndConditions: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // console.log('view initialized');
    setTimeout(() => {
      // this.show = true;
      let content = '';
      content = `<healcode-widget `;
      if (!!this.version) {
        content += ` data-version="${this.version}"`;
      }
      if (!!this.linkClass) {
        content += ` data-link-class="${this.linkClass}"`;
      }
      if (!!this.type) {
        content += ` data-type="${this.type}"`;
      }
      if (!!this.innerHtml) {
        content += ` data-inner-html="${this.innerHtml}"`;
      }
      if (!!this.widgetPartner) {
        content += ` data-widget-partner="${this.widgetPartner}"`;
      }
      if (!!this.widgetId) {
        content += ` data-widget-id="${this.widgetId}"`;
      }
      if (!!this.widgetVersion) {
        content += ` data-widget-version="${this.widgetVersion}"`;
      }
      if (!!this.siteId) {
        content += ` data-site-id="${this.siteId}"`;
      }
      if (!!this.mbSiteId) {
        content += ` data-mb-site-id="${this.mbSiteId}"`;
      }
      if (!!this.serviceId) {
        content += ` data-service-id="${this.serviceId}"`;
      }
      if (!!this.contractId) {
        content += ` data-contract-id="${this.contractId}"`;
      }
      if (!!this.hasTermsAndConditions) {
        content += ` data-has-terms-and-conditions="${this.hasTermsAndConditions}"`;
      }
      content += `></healcode-widget>`;
      // console.log('injecting content', content);
      this.content = content;
    }, this.delay);
  }

  ngAfterContentChecked(): void {
    // this.content = this.el.nativeElement.innerText;
    // console.log('got transcluded content: ', this.el, this.el.nativeElement);
  }
}
