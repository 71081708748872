import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit, AfterViewInit {
  // @ViewChild('healCode', { read: ViewContainerRef, static: false }) healCode: ViewContainerRef;
  content = '<healcode-widget data-type="schedules" data-widget-partner="presenters" data-widget-id="a381787a015" data-widget-version="1" ></healcode-widget>'
  showHealCode = false;

  constructor(private ngZone: NgZone, public baseComponent: BaseComponent) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showHealCode = true;
    });
  }
}
