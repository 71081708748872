import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hover-block',
  templateUrl: './hover-block.component.html',
  styleUrls: ['./hover-block.component.scss']
})
export class HoverBlockComponent implements OnInit {
  @Input() url;
  @Input() title;
  @Input() toggle;
  @Input() target;
  @Input() label;
  @Input() labelTitle;
  @Input() image;

  constructor() { }

  ngOnInit() {
  }

}
