import { Component, NgZone, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pricepanel-intro',
  templateUrl: './pricepanel-intro.component.html',
  styleUrls: ['./pricepanel-intro.component.scss']
})
export class PricepanelIntroComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {
  }

  ngOnInit() {
  }

}
