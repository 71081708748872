import { ViewChild, Component, Input, OnInit } from '@angular/core';
import { Post } from '../../../generated/models';
import { APIClient } from '../../../generated';

@Component({
  selector: 'app-news-card',
  templateUrl: 'news-card.html',
  styleUrls: ['news-card.scss']
})
export class NewsCardComponent implements OnInit {
  @Input() post: Post;
  image: string;

  constructor(private apiClient: APIClient) {
  }

  async ngOnInit() {
    console.log('init()', this.post.id);
    // this.image = await this.getPostImage(this.post.id);
  }

  getPostImageUrl(postId: number): string {
    return `/api${this.post.heroImage}`;
  }

  // async getPostImage(postId: number): Promise<string> {
  //   console.log('getPostImage() 2', postId);
  //   const heroImage = await this.apiClient.getOrgsOrgnamePostIdFieldname({
  //     orgName: 'global',
  //     id: postId,
  //     fieldName: 'heroImage'
  //   }, {
  //     responseType: 'blob'
  //   }).toPromise();
  //   return heroImage;
  // }
}
