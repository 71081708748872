import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-studio-address',
  templateUrl: './studio-address.component.html',
  styleUrls: ['./studio-address.component.scss']
})
export class StudioAddressComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {
  }

  ngOnInit() {
  }

}
