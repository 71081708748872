import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { APIClient } from '../../../generated';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as moment from 'moment'
declare var $: any;
const todaysDate = moment().format("MM/DD/YYYY");
const endDate = moment().add(1, 'months').format("MM/DD/YYYY");

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavMenuComponent implements OnInit {
  newsCount = 0;
  classeventsTest = false;
  apiURL = 'https://app.jumafit.com/getclass';
  classeventsThree: any[];
  constructor(private ngZone: NgZone, public baseComponent: BaseComponent, private apiClient: APIClient,private http: HttpClient) {
  }
  
  

  async ngOnInit() {
    // $.get({
    //     method: 'GET',
    //     url: `${this.apiURL}`
    //   }).done(function(responsedata) {
    //     console.log("got result: ", responsedata);
    //     this.newsCount = 6;
    //      this.classevents = responsedata['Classes'].map(item => {
    //         if(item.IsAvailable){
             
    //             var classLink = `<a target="_blank" href="https://cart.mindbodyonline.com/sites/59430/cart/add_booking?item[info]=${item.StartDateTime}&item[mbo_id]=${item.Id}&item[mbo_location_id]=${item.Location.Id}&item[name]=${item.ClassDescription.Name}&item[type]=${item.ClassDescription.Program.ScheduleType}"> Book</a>`;
    //         }else{
    //             var classLink = ``;
    //         }
            
    //         return {
    //             "title":"<b>"+item.ClassDescription.Name+"</b>",
    //             "starttime":": "+moment(item.StartDateTime).format("h:mm a")+" - "+moment(item.EndDateTime).format("h:mm a")+ " EST",
    //             "name":item.Staff.FirstName+" "+item.Staff.LastName,
    //             "classlink":classLink
    //         };
    //      }) 
    //      this.classeventsTest = true
       
    //   });
    

    // await this.http.get(`${this.apiURL}`).subscribe(responsedata =>{
    //     console.log("got result: ", responsedata);
    //     this.classeventsTest = true;
    //     console.log("got classeventsTest: ", this.classeventsTest);
    // });


    this.classeventsThree = []
    await this.http.get(`${this.apiURL}`).subscribe(responsedata =>{
      
      if(responsedata['Classes'].length > 0){
        responsedata['Classes'].map(item => {
          if(item.IsAvailable && !item.IsCanceled){
             
             var classLink = `https://cart.mindbodyonline.com/sites/59430/cart/add_booking?item[info]=${item.StartDateTime}&item[mbo_id]=${item.Id}&item[mbo_location_id]=${item.Location.Id}&item[name]=${item.ClassDescription.Name}&item[type]=${item.ClassDescription.Program.ScheduleType}`;
         
          
             this.classeventsThree.push({
             "title":item.ClassDescription.Name,
             "starttime":": "+moment(item.StartDateTime).format("h:mm a")+" - "+moment(item.EndDateTime).format("h:mm a")+ " EST",
             "name":item.Staff.FirstName+" "+item.Staff.LastName,
             "classlink":classLink
           });
        }
       }) 
      }else{
        this.classeventsThree = []
      }
        
      });

      
    // const res = await this.apiClient.getOrgsOrgnamePostCount({
    //   orgName: 'global',
    // }).toPromise();
    // this.newsCount = 5;
  }

}
