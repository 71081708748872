import { Component } from '@angular/core';
//import { ClassService } from '../../class.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
declare var $: any;
import * as moment from 'moment'
const todaysDate = moment().format("MM/DD/YYYY");
const endDate = moment().add(1, 'months').format("MM/DD/YYYY");
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})

export class ScheduleComponent {
   classevents: any[];
   isdataavailable: any;
   isLoading: boolean = true;
 // constructor(private classService: ClassService){}
  constructor(private http: HttpClient) {}
  title = 'Juma fullcalendar';
  apiURL = 'https://app.jumafit.com/getclass';
  
 

ngOnInit(){
  if (window.innerWidth >= 768 ) {
      var cview = 'basicWeek';
   } else {
      var cview = 'basicDay';
   }
 
   this.isdataavailable = false
//console.log("apiURLapiURLapiURL",this.apiURL)
this.classevents = []
this.http.get(`${this.apiURL}`).subscribe(responsedata =>{
      //console.log("responsedata",responsedata)
      
      responsedata['Classes'].map(item => {
         if(item.IsAvailable && !item.IsCanceled){
            
            var classLink = `<a target="_blank" href="https://cart.mindbodyonline.com/sites/59430/cart/add_booking?item[info]=${item.StartDateTime}&item[mbo_id]=${item.Id}&item[mbo_location_id]=${item.Location.Id}&item[name]=${item.ClassDescription.Name}&item[type]=${item.ClassDescription.Program.ScheduleType}"> Book</a>`;
            this.classevents.push({
              "title":"<b>"+item.ClassDescription.Name+"</b>",
              "start":moment(item.StartDateTime).format("YYYY-MM-DD HH:mm"),
              "starttime":moment(item.StartDateTime).format("h:mm a")+" - "+moment(item.EndDateTime).format("h:mm a")+ " EST",
              "color":randomcolor[Math.floor(Math.random() * randomcolor.length)],
              "name":item.Staff.FirstName+" "+item.Staff.LastName,
              "description":item.ClassDescription.Description,
              "classlink":classLink
            });
         }
         
        
      }) 
      

  
     
  });
var randomcolor = [
  '#f9c66a','#019efb'
];


  
// this.classService.listClass().subscribe((res)=>{
//   console.log(res);   
// });
       setTimeout(() => {
         this.isdataavailable = true
         //console.log("this.classevents",this.classevents)
        $("#calendar").fullCalendar({  
                        header: {
                          left: 'prev',
                          center: 'title',
                          right: 'next'
                        },
                        defaultView: cview,
                        navLinks   : false,
                        editable   : false,
                        eventLimit : false,
                        allDaySlot: false,
                        displayEventTime: false,
                        slotEventOverlap:false,
                        views: {
                         
                          week: {
                            columnFormat: 'MMM DD ddd' // set format for week here
                          },
                         
                        },
                        buttonText: {
                          prev:'<',
                          next:'>',
                         
                      },
                      events: this.classevents,
                        
                        "textEscape": false, 
                        eventRender: function(event, element) {
                            element.css("font-size", "1.2em");
                            element.css("padding", "10px");
                            element.css("white-space","normal");
                           // event.find('.fc-title').innerHTML = "<i>" + event.title + "</i>";
                            //element.find(".fc-title").prepend("<h1>"+event.title+"</h1>");
                            element.find(".fc-title").remove();
                            var new_description =   
                                //moment(event.start).format("HH:mm") + '-'
                                event.title + '<br/>'
                                + event.starttime + '<br/>'
                                + event.name + '<br/>'
                                
                            ;
                            element.find(".fc-content").append(new_description);
                            element.popover({
                              title: event.title,
                              placement: 'bottom',
                              html: true,
                              animation:false,
                              container: 'body',
                              content: function () {
                                  return `<div class="row">
                                  <div class="col-md-12">
                                    <h3>${event.starttime}</h3>
                                    <p>${event.description}</p>
                                    <p>${event.classlink}</p>
                                    
                                  </div>
                                </div>`
                              },
                              trigger: "manual"
                                  }).on("mouseenter", function () {
                                      var _this = this;
                                      $(this).popover("show");
                                      $(".popover").on("mouseleave", function () {
                                          $(_this).popover('hide');
                                      });
                                  }).on("mouseleave", function () {
                                      var _this = this;
                                      //setTimeout(function () {
                                          if (!$(".popover:hover").length) {
                                              $(_this).popover("hide");
                                          }
                                     // }, 300);
                                  });
                                
                        }
                    });
                    this.isLoading = false; 
     }, 5000);
     dayHeaderContent: (args) => {
        return moment(args.date).format('ddd Do')
    }
   }
}