import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Pipe, PipeTransform } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HtmlPipe, SafePipe } from './safe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FooterComponent } from './components/footer/footer.component';
import { PricepanelIntroComponent } from './components/pricepanel-intro/pricepanel-intro.component';
import { PricepanelIntroComponentExperience } from './components/pricepanel-intro-experience/pricepanel-intro.component';
import { PricepanelPrimaryComponent } from './components/pricepanel-primary/pricepanel-primary.component';
import { PricepanelSecondaryComponent } from './components/pricepanel-secondary/pricepanel-secondary.component';
import { PricepanelPrivateComponent } from './components/pricepanel-private/pricepanel-private.component';
import { StudioAddressComponent } from './components/studio-address/studio-address.component';
import { CharityComponent } from './pages/charity/charity.component';
import { PressComponent } from './pages/press/press.component';
import { MentorsComponent } from './pages/mentors/mentors.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { StudioComponent } from './pages/studio/studio.component';
import { SmallgroupsComponent } from './pages/smallgroups/smallgroups.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { JourneyComponent } from './pages/journey/journey.component';
import { AboutComponent } from './pages/about/about.component';
import { ButtonComponent } from './components/button/button.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { SliderComponent } from './components/slider/slider.component';
import { SlideComponent } from './components/slide/slide.component';
import { StatsComponent } from './components/stats/stats.component';
import { LinkComponent } from './components/link/link.component';
import { HoverBlockComponent } from './components/hover-block/hover-block.component';
import { BillboardComponent } from './components/sections/billboard/billboard.component';
import { FullVidComponent } from './components/sections/full-vid/full-vid.component';
import { InsetComponent } from './components/sections/inset/inset.component';
import { ParallaxComponent } from './components/sections/parallax/parallax.component';
import { ParallaxVidComponent } from './components/sections/parallax-vid/parallax-vid.component';
import { WorkgridComponent } from './components/sections/workgrid/workgrid.component';
import { ModalComponent } from './components/sections/modal/modal.component';
import { LogocompareComponent } from './components/sections/logocompare/logocompare.component';
import { NarrowComponent } from './components/sections/narrow/narrow.component';
import { MidComponent } from './components/sections/mid/mid.component';
import { WideComponent } from './components/sections/wide/wide.component';
import { FeaturedTileComponent } from './components/worktiles/featured-tile/featured-tile.component';
import { FeaturedTileVideoComponent } from './components/worktiles/featured-tile-video/featured-tile-video.component';
import { TileComponent } from './components/worktiles/tile/tile.component';
import { BlankComponent } from './components/worktiles/blank/blank.component';
import { EmbedComponent } from './components/embed/embed.component';
import { Aspect16by9Component } from './components/aspect16by9/aspect16by9.component';
import { TermsComponent } from './pages/terms/terms.component';
import { TreadmillsComponent } from './pages/treadmills/treadmills.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { NgPipesModule } from 'ngx-pipes';
import { HealcodeWidgetComponent } from './components/healcode-widget/healcode-widget.component';
import { PageInfoComponent } from './components/page-info/page-info.component';
import { NewsComponent } from './pages/news/news.component';
import { APIClientModule } from '../generated';
import * as config from '../config.json';
import { NewsCardComponent } from './components/news-card/news-card';
import { AboutNavComponent } from './components/about-nav/about-nav.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GradientPanelComponent } from './components/gradient-panel/gradient-panel.component';
//import { ScheduleModule } from './pages/schedule/schedule.module';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CovidComponent } from './pages/covid/covid.component';
import { CdcGuidelineComponent } from './components/cdc-guideline/cdc-guideline.component';

import * as $ from "jquery";
import * as moment from 'moment'
@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(value: string, replaceValue: string): string {
    if (!!value) {
      return value.replace(value, replaceValue);
    }
    return '';
  }
}

@NgModule({
  declarations: [
    SafePipe,
    HtmlPipe,
    ReplacePipe,
    AppComponent,
    HomePageComponent,
    NavMenuComponent,
    FooterComponent,
    PricepanelIntroComponent,
    PricepanelIntroComponentExperience,
    PricepanelPrimaryComponent,
    PricepanelSecondaryComponent,
    PricepanelPrivateComponent,
    StudioAddressComponent,
    CharityComponent,
    PressComponent,
    MentorsComponent,
    ClassesComponent,
    PricingComponent,
    StudioComponent,
    SmallgroupsComponent,
    ExperienceComponent,
    JourneyComponent,
    AboutNavComponent,
    AboutComponent,
    ContactComponent,
    ButtonComponent,
    GalleryComponent,
    SliderComponent,
    SlideComponent,
    StatsComponent,
    BillboardComponent,
    FullVidComponent,
    InsetComponent,
    ParallaxComponent,
    ParallaxVidComponent,
    WorkgridComponent,
    ModalComponent,
    LogocompareComponent,
    NarrowComponent,
    MidComponent,
    WideComponent,
    LinkComponent,
    HoverBlockComponent,
    EmbedComponent,
    Aspect16by9Component,
    FeaturedTileComponent,
    FeaturedTileVideoComponent,
    TileComponent,
    BlankComponent,
    NotFoundComponent,
    TermsComponent,
    TreadmillsComponent,
    PrivacyComponent,
    HealcodeWidgetComponent,
    PageInfoComponent,
    NewsComponent,
    NewsCardComponent,
    GradientPanelComponent,
    ScheduleComponent,
    CovidComponent,
    CdcGuidelineComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    NgPipesModule,
    APIClientModule.forRoot({
      domain: config.apiBaseUrl,
      httpOptions: {}
    }),
    ScrollToModule.forRoot(),
    //ScheduleModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

