import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-mentors',
  templateUrl: './mentors.component.html',
  styleUrls: ['./mentors.component.scss']
})
export class MentorsComponent implements OnInit {
  @ViewChild('mentorgia', {static : true}) mentorgia:ElementRef;
  constructor(public baseComponent: BaseComponent) {

  }

  ngOnInit() {
    if (window.location.href.indexOf('?f=1') > 0) {
      setTimeout(() =>{
        this.mentorgia.nativeElement.click();;
      }, 1000);
    }
  }

}
