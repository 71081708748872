import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-pricepanel-primary',
  templateUrl: './pricepanel-primary.component.html',
  styleUrls: ['./pricepanel-primary.component.scss']
})
export class PricepanelPrimaryComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {
  }

  ngOnInit() {
  }

}
