import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  constructor(public baseComponent: BaseComponent) {

  }

  ngOnInit() {
  }

}
