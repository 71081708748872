import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss']
})
export class PageInfoComponent implements OnInit {
  @Input() bodyClass = '';
  @Input() pageCategory = 'Home';
  @Input() categoryURL = '/';
  @Input() pageTitle = 'juma';
  @Input() pageURL = 'http://www.jumafit.com/about';
  @Input() pageImage = 'http://www.jumafit.com/images/fb-og-image.jpg';
  @Input() pageDescription = 'juma jumafit';

  constructor(public baseComponent: BaseComponent) { }

  ngOnInit() {
    // console.log('PageInfoComponent(): updating page info');
    this.baseComponent.updatePageInfo({
      bodyClass: this.bodyClass,
      pageCategory: this.pageCategory,
      categoryURL: this.categoryURL,
      pageTitle: this.pageTitle,
      pageURL: this.pageURL,
      pageImage: this.pageImage,
      pageDescription: this.pageDescription
    });
  }

}
