import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  @Input() number;
  @Input() link;
  @Input() image;
  @Input() imageAlt;
  @Input() btnText;
  @Input() btnStyle;
  @Input() styleAttr;

  constructor() { }

  ngOnInit() {
  }

}
