import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gradient-panel',
  templateUrl: './gradient-panel.component.html',
  styleUrls: ['./gradient-panel.component.scss']
})
export class GradientPanelComponent implements OnInit {
  @Input() startGradientColor = 'rgba(238,188,97,1)';
  @Input() endGradientColor = 'rgba(157,106,163,1)';

  constructor(public baseComponent: BaseComponent, protected sanitizer: DomSanitizer) {

  }

  ngOnInit() {
  }

  getBackground() {
    return this.sanitizer.bypassSecurityTrustStyle(`${this.startGradientColor} linear-gradient(45deg, ${this.startGradientColor} 0%, ${this.endGradientColor} 100%)`);
  }
}
