import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wide',
  templateUrl: './wide.component.html',
  styleUrls: ['./wide.component.scss']
})
export class WideComponent implements OnInit {
  @Input() id = '';
  @Input() sectionStyle = '';
  @Input() color = '';
  @Input() padding = '';

  constructor() { }

  ngOnInit() {
  }

}
